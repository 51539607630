<template>
  <div class="login">
    <div class="login_main flex_direction_column">
      <div class="top flex">
        <img src="@/assets/image/logo_three.png" />
        <span>赛小狸赛事管理平台</span>
      </div>
      <div class="middle flex_space_between">
        <img class="yun" src="@/assets/image/img/39.png" />
        <div class="middle_left">
          <img src="@/assets/image/img/40.png" />
        </div>
        <div class="middle_right flex_direction_column">
          <div class="title">组织方管理员 账号登录</div>
          <div class="form">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
              <el-form-item label="手机号" prop="phone">
                <img class="icon" src="@/assets/image/icon/26.png" />
                <el-input placeholder="请输入手机号" v-model="ruleForm.phone"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="pass">
                <img class="icon" src="@/assets/image/icon/27.png" />
                <el-input placeholder="请输入密码" v-model="ruleForm.pass"></el-input>
              </el-form-item>
            </el-form>
            <div class="btn" @click="loginClick">登录</div>
            <div class="desc flex_center">
              <span class="flex">注册组织方</span>
              <span>忘记密码</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { phoneReg } from '@/utils/regular.js'
const validatePhone = (rule, value, callback) => {
  phoneReg(value)
    .then(() => {
      callback()
    })
    .catch((err) => {
      return callback(new Error(err))
    })
}
export default {
  data() {
    return {
      ruleForm: {
        phone: '',
        pass: ''
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        pass: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },

  mounted() {},

  methods: {
    loginClick() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log('success')
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-form-item__label:before {
  content: ' ' !important;
  margin-left: 1.25rem;
}
/deep/ .el-form-item__label {
  text-align: left;
}
/deep/ .el-form-item {
  margin-top: 3.4375rem;
  border-bottom: 1px solid #dddddd;
}

/deep/ .el-input__inner {
  padding: 0;
  border: none;
  background-color: transparent;
}
.icon {
  top: 0.625rem;
  width: 1.2rem;
  height: 1.2rem;
  left: -6rem;
  position: absolute;
}
.login {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
  .login_main {
    padding: 5rem 21.625rem 3.375rem 9.4375rem;
    .top {
      img {
        width: 3.5rem;
        height: 3.5rem;
      }
      span {
        height: 3rem;
        margin-left: 0.625rem;
        font-weight: bold;
        line-height: 3rem;
        font-size: @font_size_34;
      }
    }
    .middle {
      position: relative;
      margin-top: 230rpx;
      .yun {
        top: -6.5625rem;
        right: 0;
        width: 10rem;
        height: 3.75rem;
        position: absolute;
      }
      .middle_left {
        width: 48.125rem;
        height: 36.5625rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .middle_right {
        width: 25.25rem;
        margin-left: 15.375rem;
        .title {
          height: 2.5rem;
          line-height: 2.5rem;
          color: @color_one;
          font-size: @font_size_28;
        }
        .form {
          width: 100%;
          .btn {
            color: #fff;
            height: 2.75rem;
            cursor: pointer;
            margin-top: 3.4375rem;
            line-height: 2.75rem;
            text-align: center;
            border-radius: 0.1875rem;
            font-size: @font_size_16;
            background-color: @color_one;
          }
          .desc {
            margin-top: 1.25rem;
            span {
              height: 1.375rem;
              cursor: pointer;
              line-height: 1.375rem;
              font-size: @font_size_16;
              &:nth-child(1) {
                color: @color_one;
                &::after {
                  width: 1px;
                  content: '';
                  height: 0.75rem;
                  margin: 0 2.25rem;
                  background-color: #ddd;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
